$font-stack: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$title-font-stack: Serif;
$monospace-font-stack: "Ubuntu Mono",monospace;
$subtitle-font-stack: Serif;

$text-color: #eaeaea;
$light-text: #cacaca;
$link-color: #88f;
$accent-color: #FFAA1C;
$complement-color: #592C84;
$complement-color2: #006B2D;
$dark-red: #802e3a;
$less-black: #575757;
$black: #353535;
$almost-black: #111111;
$off-white: #eaeaea;
$offer-white: #bababa;
$offerer-white: #cacaca;

$background: #202020;

$slate: #393b44;

$small-screen: 767px;
$medium-screen: 992px;
$large-screen: 1200px;
$very-large-screen: 1824px;

@mixin respond-to($breakpoint) {
  @if $breakpoint == "small" {
    @media (min-width: $small-screen) {
      @content;
    }
  }

  @else if $breakpoint == "medium" {
    @media (min-width: $medium-screen) {
      @content;
    }
  }

  @else if $breakpoint == "large" {
    @media (min-width: $large-screen) {
      @content;
    }
  }

  @else if $breakpoint == "very-large" {
    @media (min-width: $very-large-screen) {
      @content;
    }
  }
}
