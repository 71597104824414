@import "vars.scss";
@import "highlight.scss";

body {
  color: $text-color;
  font-family: $font-stack;
  font-size: 1em;
  background: $background;
  margin: 0;
  padding: 0;
}

.sidebar {
  margin: 0 1em;
  @include respond-to("small") {
    width: 20em;
    position: fixed;
    height: 100vh;
    border-right: 2px solid $complement-color;
    padding-left: 1.5em;
  }
}

a {
  color: $link-color;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

nav {
  padding-top: 1em;
  font-size: 1.3em;
  width: auto;
  h1, li {
    margin-right: 1em;
  }

  ul {
    list-style: none;
  }
}

nav, .toc-block {
  li {
    display: inline-block;
    min-width: 51%;
  }
  a {
    margin: 0;
    padding: 0;
    text-decoration: none;
    &:link, &:visited {
      color: $text-color;
    }
    &:hover {
        text-decoration: none;
        color: $light-text;
        &:after {
          width: 100%;
          background: $accent-color;
        }
    }

    &:after {
      content: '';
      display: block;
      height: 2px;
      width: 0;
      background: transparent;
      transition: width .5s ease, background-color .5s ease;
    }

  }
}

.content {
  padding: 1em;
  margin-bottom: 3em;
  @include respond-to("small") {
    margin-left: 21em;
    padding: 1em 3em;
    max-width: 60%;
  }
}

.title {
  font-size: 2.5em;
}

.prompt {
  font-family: $monospace-font-stack;
  color: $complement-color2;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 2em;
  color: $complement-color2;
  margin-top: 2em;

}

.subtitle {
  font-size: 2em;
}

h3 {
  font-size: 1.5em;
  color: $complement-color2;
  margin-top: 2em;
}

h4 {
  font-size: 1.5em;
  margin-top: 2em;
}

.monospace {
  font-family: $monospace-font-stack;
}

li, p {
  color: $text-color;
  font-size: 1rem;
  line-height: 1.5em;
}

.typed {
  animation: blink .5s step-end infinite alternate;
  padding-right: 0.5em;
  border-right: 3px solid;
  color: $text-color;
}

.posts-list {
  list-style: none;
  font-family: $monospace-font-stack;
  padding: 0;

  li:before {
    content: '>';
    margin: 0 1em;
  }
  li {
    margin: 1em 0;
    a {
      font-family: $font-stack;
    }
  }
}

@keyframes blink {
  50% {
    border-color: transparent
  }
}

blockquote {
  background: $slate;
  border-radius: 1em;
  padding: 0.5em;
}

.titlebar {
  color: $complement-color;
}

img {
  padding: 0.5em;
  border-radius: 1em;
  max-width: 90%;
  display: block;
}

.toc-block {
  height: 50%;
  #toc {
    overflow-y: auto;
    height: 100%;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  th {
    background-color: #272727;
    font-weight: bold;
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid $accent-color;
  }

  tr {
    background-color: $black;
  }

  tr:nth-child(even) {
    background-color: $less-black;
  }

  td {
    padding: 10px;
    border-bottom: 1px solid $complement-color;
  }
}

.track {
  padding: 1em;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  audio {
    width: 100%;
  }
  p {
    margin-top: 0.1em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

#rss {
  width: 2em;
  height: 2em;
  display: block;
  float: right;
}

.series {
  border-bottom: 2px solid $complement-color;
}
